import { TOrder } from '@components/types';
import { getFirst, getLength, humanizeCurrency, trimToLength } from '@core/utils';
import { getSafeUrl } from '@core/utils';
import moment from 'moment';
import Link from 'next/link';
import { MdCheckCircleOutline } from 'react-icons/md';
import { MdPayments } from 'react-icons/md';
import { FaShoppingBag } from 'react-icons/fa';
import { useState } from 'react';
import Ordertracker from '../track/ordertracker';


type Props = {
    editable?: boolean
    order: TOrder
    setOrderStage?: Function
    setDetailOrder?: Function
    noLink?: boolean
};


export const OrderSummaryCard = ({ order, setOrderStage, setDetailOrder, noLink = false }: Props) => {
    const [openTrackOrder, setOpenTrackOrder] = useState(false);

    return (
        <div className="bg-neutral-50 flex flex-col gap-2 p-4 rounded-md border border-solid border-neutral-300" data-order-id={order?.id}>
            <div className="border-b border-b-solid border-neutral-300 pb-2" onClick={() => { setDetailOrder && setDetailOrder(order); setOrderStage && setOrderStage("detailorder") }}>
                <div className="flex flex-col gap-1 font-manrope">
                    <div className="flex justify-between items-center">
                        {noLink ? <h2 className="md:text-lg text-base  font-semibold text-neutral-700">{order?.store?.name}</h2> : <Link href={`/order/me/${order?.id}`}><h2 className="md:text-lg text-base  font-semibold text-neutral-700">{order?.store?.name}</h2></Link>}
                        {
                            order.delivery_mode == "Delivery" ? <span className="bg-business-100 rounded px-2 py-1 text-sm font-semibold text-bannerText">DELIVERY</span> :
                                <span className="bg-brand-100 rounded px-2 py-1 text-sm font-semibold text-bannerText">PICKUP</span>
                        }
                    </div>
                    <p className="md:text-base text-sm text-neutral-500 font-manrope">{`${order?.store?.address?.street_name}, ${order?.store?.address?.city?.name}`}</p>
                    <div className="flex justify-between items-center">
                        <span className="text-lg text-neutral-700 font-bold">{humanizeCurrency(order?.payable)}</span>
                        <div className="flex flex-row gap-1">
                            <p className="text-base text-custom_gray font-medium flex gap-1 items-center"><MdPayments /><span>{order?.payment_mode}</span></p>
                            <span className={`text-base font-bold ${order?.is_paid ? "text-success-500" : "text-error-500"}`}>
                                {order?.is_paid ? "Paid" : "Pending"}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-2">
                    <h2 className="md:text-base text-sm text-neutral-500">{order.updated_on != null ? `${moment(order?.updated_on).format("MMMM DD, YYYY h:mm A")}` : moment(order?.added_on).format("MMMM DD, YYYY | h:mm A")}</h2>
                    <div className="flex items-center justify-between">
                        {getLength(order.orderitems) > 1 ? <span className="font-semibold text-neutral-700 line-clamp-1">
                            {`${trimToLength(getFirst(order?.orderitems)?.inventory?.product?.name, 30)} + ${getLength(order.orderitems) - 1} items`}
                        </span>
                            : <span className="font-manrope font-medium text-neutral-700 line-clamp-1">{trimToLength(getFirst(order.orderitems)?.inventory?.product?.name, 30)}</span>
                        }
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex justify-between items-center">
                    <div className='flex items-center gap-1'>
                        <h2 className="flex gap-1 items-center md:text-base text-sm font-normal text-green-600">{order?.status === "Completed" ? <MdCheckCircleOutline size={20} className="text-green-600" /> : <FaShoppingBag className="text-brand-500" />} <span className={`font-manrope font-medium ${order?.status == "Completed" ? "text-green-600" : "text-neutral-700"}`}>Order {order?.status}</span></h2>
                    </div>
                    <div className="flex items-center gap-2">
                        <button
                            onClick={() => { setOpenTrackOrder(true); }}
                            className='flex-1 text-sm p-1 border-1 border-solid border-brand-500 text-brand-500 bg-neutral-50 rounded font-medium font-manrope max-w-32 whitespace-nowrap cursor-pointer'>TRACK ORDER</button>
                        <button className={`flex-1 text-sm p-1 border-1 border-solid border-brand-500 text-neutral-50 bg-brand-500 rounded font-medium font-manrope max-w-32 cursor-pointer ${order?.status === "Completed" ? "" : "hidden"}`}>REORDER</button>
                    </div>
                </div>
            </div>
            <Ordertracker
                showViewDetails={openTrackOrder}
                setShowViewDetails={setOpenTrackOrder}
                order={order}
            />
        </div>
    )
}